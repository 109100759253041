import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { Layout, Pagenation } from "../components/index"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { LowerHeader, LowerHeaderBg } from "../components/LowerPages/index"
import "../styles/LowerPage.scss";
import Img from "gatsby-image"
import { CategoryMenu } from '../components/index'
import "../styles/Category.scss"

const Category = ({ data, pageContext }) => {
  const breakpoints = useBreakpoint();
  const { category } = pageContext//gatsby-node.js側でcreatePageで設定したcontextを呼ぶ
  const catName = data.allWpPost.edges[0].node.categories.nodes[0].name
  const catSlug = data.allWpPost.edges[0].node.categories.nodes[0].slug
  console.log(catSlug);
  const title1 = [
    { "id": 0, "text": "n" },
    { "id": 1, "text": "e" },
    { "id": 2, "text": "w" },
    { "id": 3, "text": "s" }
  ];
  return (
    <Layout className={category} pageName={`news`} title={catName}>
      <Seo
        title={catName}
      />
      <div className={`LowerHeadImg`}>
        {
          breakpoints.sm ?
            <StaticImage
              layout="fullWidth"
              src="../images/news/news_header-sp.png"
              alt="news_header"
            />
            :
            <StaticImage
              layout="fullWidth"
              src="../images/news/news_header.png"
              alt="news_header"
            />
        }
      </div>
      <div className={`LowerWrapper`}>
        <LowerHeader data={title1} title={`お知らせ`}>
          <LowerHeaderBg />
        </LowerHeader>
        <div className={`LowerContent`}>
          <div className={`RadiusCircle`} ></div>
          <div className={`LowerContent__inner`}>
            <CategoryMenu catSlug={catSlug} />
            <ul className={`categoryList`}>
              {data.allWpPost.edges.map(({ node }) => (
                <li key={node.slug}>
                  <a href={node.link}>
                    <Img
                      fluid={node.featuredImage.node.localFile.childImageSharp.fluid}
                      alt={node.title}
                    />
                    <div className={`listInfo`}>
                      <span className={`listCat ` + node.categories.nodes[0].slug}>{node.categories.nodes[0].name}</span>
                      <span className={`listDate`}>{node.date}</span>
                    </div>
                    <h3 className={`listTitle`}>
                      {node.title}
                    </h3>
                  </a>
                </li>
              ))}
            </ul>
            <Pagenation pageContext={pageContext} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Category;

export const query = graphql`
  query ($category: String!,$skip: Int!, $limit: Int!) {
    allWpPost(
        filter: { categories: { nodes: { elemMatch: { slug: { eq: $category } } } } }
        skip: $skip 
        limit: $limit 
      ) {
      edges {
        node {
          categories{
            nodes{
              name
              slug
              link
            }
          }
          date(formatString: "YYYY.MM.DD")
          slug
          title
          link
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                 fluid {
                   ...GatsbyImageSharpFluid_withWebp_tracedSVG
                 }
                }
              }
            }
          }
        }
      }
    }
  }
`